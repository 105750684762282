<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="false" :show-backdrop="true">
    <div class="inner-content d-flex flex-column px-4 py-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="title">Feedback</div>
        <ion-buttons>
          <ion-button class="close-button text-black" @click="dismissModal">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </div>

      <div class="mt-2 d-flex justify-content-center">Share a thank you note to the creator</div>

      <ion-textarea
        v-model="feedback_note"
        rows="3"
        class="c-textarea mt-2"
        placeholder="Use lots of exclamation points and show how happy you are! Thank yous make creators extremely happy!"
      ></ion-textarea>

      <div class="d-flex justify-content-center mt-2">
        <ion-button class="submit-btn" :disabled="isSubmitting" type="submit" @click.prevent="dismissModal">
          <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
          <span v-else>Submit</span>
        </ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import FanWorkFeedBackModal from './FanWorkFeedBackModal.vue';
import { createFeedBack } from '@/shared/actions/fanwork';
import { toast } from '@/shared/native';

@Options({
  name: 'ThankYouNoteToCreator',
  components: { FanWorkFeedBackModal },
})
export default class ThankYouNoteToCreator extends Vue {
  @Prop({}) note!: string;
  @Prop({}) id!: string;
  @Prop({}) reactions!: any;
  @Prop({}) username!: any;
  @Prop({ default: false }) isOpen!: boolean;

  public feedback_note = '';
  public isSubmitting = false;

  public async dismissModal() {
    this.isSubmitting = true;

    await createFeedBack(this.id, {
      feedback_note: this.feedback_note,
      feedback_reactions: this.reactions,
    });

    this.isSubmitting = false;

    await toast.show(
      `Your note was shared with ${this.username}! Thanks for spreading the love!`,
      'nonative',
      'success'
    );
    this.$emit('dismissModal');
  }
}
</script>

<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 22px
.submit-btn
  width: 150px

ion-modal
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 80vh
  overflow: auto
</style>
