<template>
  <div>
    <div class="sub-title mt-4">
      Unopened {{ isDec ? 'Decoration' : 'Fanwork' }}
      <span v-if="totalCount"> ({{ totalCount }}) </span>
    </div>
    <div class="pt-2 pb-3">
      {{
        isDec
          ? ''
          : `Did Christmas come early? Click on the cards to open fan art, fanfic, and fan
      poems you got!`
      }}
    </div>
    <div v-if="unopenedFanwork && unopenedFanwork.length">
      <fan-work-card
        v-for="fanwork in currentPageFanWorks"
        :key="fanwork.id"
        :fanwork="fanwork"
        :is-dec="isDec"
        @updated="getUnOpenedFanWork"
        @opened="getOpenedFanWork"
      />
    </div>
    <div v-else class="no-data">
      This spot will fill up with cool {{ isDec ? 'OC decorations' : 'fan art or fan mail' }} you get! Stay tuned!
    </div>

    <div v-if="unopenedFanwork && unopenedFanwork.length" class="d-flex justify-content-end py-2">
      <ion-button class="back-btn mx-2" @click="setCurrentPage(-1)">
        <span> <i class="ti-angle-left icon" /></span>
      </ion-button>
      <div class="mx-1 mt-1">
        <ion-badge class="badge paging-fanwork">
          <ion-text class="ion-no-padding ion-no-margin text-lowercase">
            {{ currentPage }} /
            {{ Math.ceil(totalCount / pageSize) }}
          </ion-text></ion-badge
        >
      </div>

      <ion-button class="back-btn mr-4" @click="setCurrentPage(1)">
        <span> <i class="ti-angle-right icon" /></span>
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FanWorkCard from '@/shared/components/FanWorkCard.vue';

@Options({
  name: 'UnopenedFanWork',
  components: { FanWorkCard },
})
export default class UnopenedFanWork extends Vue {
  @Prop({ default: () => {} }) unopenedFanwork!: any;
  @Prop({ default: () => {} }) pageSize!: any;
  @Prop({ default: () => {} }) totalCount!: any;
  @Prop({ default: () => 1 }) getCurrentPage!: any;
  @Prop({ default: () => {} }) isDec!: any;
  get = get;
  public currentPage = 1;

  @Watch('getCurrentPage')
  updatedPagination() {
    this.currentPage = this.getCurrentPage;
  }

  public get currentPageFanWorks() {
    return this.unopenedFanwork;
    // .slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
  }

  public getUnOpenedFanWork() {
    this.$emit('updated');
    this.$emit('status');
  }

  public getOpenedFanWork() {
    this.$emit('opened');
  }

  public setCurrentPage(direction: any) {
    if (direction === -1 && this.currentPage > 1) {
      this.currentPage -= 1;
    } else if (direction === 1 && this.currentPage < this.totalCount / this.pageSize) {
      this.currentPage += 1;
    }
    this.$emit('currentPage', this.currentPage);
  }
}
</script>

<style lang="sass" scoped>
.back-btn
  width: 25px
  height: 25px
.badge
  background: white
  color: black
  width: 100%
  height: 26px
.sub-title
  font-size: 20px
  font-weight: bold
ion-badge
  display: flex
  align-items: center
  justify-content: center
</style>
