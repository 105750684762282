<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="false"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="inner-content d-flex flex-column py-3">
      <div class="d-flex justify-content-between align-items-center px-4">
        <div class="title">Feedback</div>
      </div>
      <div class="my-2 px-4">What did you think of the {{ isDec ? 'Decoration' : 'FanArt' }}? Select one or more!</div>

      <div class="reaction-content px-3 mt-2">
        <div class="row mx-0">
          <div v-for="(reaction, index) in fanwork_reactions" :key="index" class="col-md-6 col-12 px-2">
            <ion-item
              button
              class="mb-2"
              color="primary"
              style="--border-radius: 30px"
              @click="() => (reaction.checked = !reaction.checked)"
            >
              <ion-checkbox
                slot="start"
                v-model="reaction.checked"
                style="margin-inline-end: 5px"
                @click.stop
              ></ion-checkbox>
              <ion-label class="text-center label-name" text-wrap>{{ reaction.name }}</ion-label>
            </ion-item>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-2">
        <ion-button class="submit-btn" type="submit" :disabled="!isFormComplete" @click.prevent="onSubmit">
          <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
          <span v-else>Submit</span>
        </ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import constants from '@/shared/statics/constants';
import { toast } from '@/shared/native';


@Options({
  name: 'FanWorkFeedBackModal',
})
export default class FanWorkFeedBackModal extends Vue {
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({}) id!: any;
  @Prop({}) isDec!: any;
  public fanwork_reactions = [];

  public get windowWidth() {
    const { width } = useWindowSize();
    return width;
  }

  isSubmitting = false;

  public feedback_reactions: any = [];
  public reaction: any[] = [];
  public feedback_note = '';

  @Watch('isOpen')
  async isOpenChanged(value: boolean) {
    if (value) {
      this.fanwork_reactions = constants.fanworkReactions.map((item: string) => ({ checked: false, name: item }));
    }
  }

  public async onSubmit() {
    this.feedback_reactions = this.fanwork_reactions
      .filter((item: { checked: boolean }) => item.checked)
      .map((item: any) => item.name);

    this.dismissModal();
    this.$emit('selected', this.feedback_reactions);
  }

  public get isFormComplete() {
    return !isEmpty(this.fanwork_reactions.filter((item: { checked: boolean }) => item.checked));
  }

  public async dismissModal() {
    !this.feedback_reactions.length
      ? await toast.show('Please select one or more options', 'nonative', 'danger')
      : this.$emit('dismissModal');
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 500;
  }
}
</script>

<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 24px

.reaction-content
  max-height: 70vh
  overflow-x: hidden
  overflow-y: auto

.label-name
  padding-right: 20px

.modal-big
  --width: 750px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 95vh
  overflow: auto

ion-item::part(native)
  color: #fff
  border-radius: 10px
  padding-inline-start: 0px
  padding-inline-end: 0px
  padding-left: 0.5rem !important
  padding-right: 0.5rem !important

ion-item::part(detail-icon)
  color: white
  opacity: 1
  font-size: 20px

ion-checkbox
  --border-radius: 6px
  --border-color: #fff
  --border-color-checked: #fff
  --checkmark-color: white !important
  --checkbox-background-checked: white
  --checkbox-background: var(--ion-color-primary)
</style>
