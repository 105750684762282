<template>
  <ion-page class="page bg-transparent">
    <div class="title mt-4">FanWork</div>
    <unopened-fan-work
      :unopened-fanwork="unOpenedFanwork"
      :page-size="5"
      :total-count="totalUnOpenedFanworkCount"
      :get-current-page="currentUnOpenedFanworkPage"
      @updated="fetchUnopenedFanWorks"
      @opened="fetchOpenedFanWorks"
      @status="getUnopenedFanWork"
      @currentPage="getUnOpenedFanworkPage"
    />

    <div>
      <div class="sub-title mt-4">
        Opened FanWork <span v-if="totalCount"> ({{ totalCount }}) </span>
      </div>
      <div class="mt-2">Pieces of FanWork you received and already opened.</div>
      <div class="d-flex py-3">
        <div class="mt-2">Filter by:</div>
        <div class="d-flex">
          <div class="mx-2">
            <multiselect
              v-model="filters.options"
              class="choose-options"
              placeholder="Character"
              :options="options"
              :multiple="false"
              :taggable="false"
              open-direction="bottom"
              :close-on-select="true"
              :searchable="false"
            >
              <template #noOptions>None!</template>
            </multiselect>
          </div>
          <div class="mx-2">
            <multiselect
              v-model="filters.character"
              class="choose-options"
              :placeholder="placeHolder"
              :options="usersChar"
              :multiple="false"
              :taggable="false"
              open-direction="bottom"
              :close-on-select="true"
              :searchable="false"
              track-by="id"
              label="name"
            >
              <template #noOptions>You don't have a character yet, go make one!</template>
            </multiselect>
          </div>
        </div>
      </div>

      <div>
        <opened-fan-work
          :fanwork="openedFanwork"
          :page-size="pageSize"
          :total-count="totalCount"
          :get-current-page="currentPage"
          @updated="fetchOpenedFanWorks"
          @currentPage="getPage"
        />
      </div>
    </div>

    <div>
      <div class="sub-title mt-4">
        Your FanWork <span v-if="totalYourFanworkCount"> ({{ totalYourFanworkCount }}) </span>
      </div>
      <div class="mt-2">
        Pieces of FanWork you've gifted other users. <strong>You can submit FanWork on any character profile!</strong>
      </div>

      <div class="d-flex py-3">
        <div class="mt-2">Filter by:</div>
        <div class="mx-2">
          <multiselect
            v-model="filters.receiver"
            class="choose-options"
            placeholder="@Username"
            :options="submittedusers"
            :multiple="false"
            :taggable="false"
            open-direction="bottom"
            :close-on-select="true"
            :searchable="false"
            track-by="id"
            label="username"
          >
            <template #noOptions>You haven't submitted any FanWork</template>
          </multiselect>
        </div>
      </div>
      <div>
        <submitted-fan-work
          :fanwork="submittedFanwork"
          :page-size="pageSize"
          :total-count="totalYourFanworkCount"
          :get-current-page="currentYourFanworkPage"
          @currentPage="getYourFanworkPage"
        />
      </div>
    </div>

    <div>
      <div id="decorations" class="title mt-4">Fan Decorations</div>
      <div class="mt-2">Decorations received in our regular "OC Decoration Fest" event. Keep your eyes peeled!</div>

      <unopened-fan-work
        :unopened-fanwork="unOpenedDec"
        :is-dec="true"
        :page-size="5"
        :total-count="totalUnOpenedDecCount"
        :get-current-page="currentUnOpenedDecPage"
        @updated="fetchUnopenedDec"
        @opened="fetchOpenedFanDecorations"
        @status="getUnopenedFanWork"
        @currentPage="getUnOpenedDecPage"
      />
      <div class="sub-title mt-2">
        Opened Decoration <span v-if="totalDecCount"> ({{ totalDecCount }}) </span>
      </div>

      <div class="d-flex py-3">
        <div class="mt-2">Filter by:</div>
        <div class="mx-2">
          <multiselect
            v-model="filters.ocDecorations"
            class="choose-options"
            :placeholder="placeHolder"
            :options="usersOcChar"
            :multiple="false"
            :taggable="false"
            open-direction="bottom"
            :close-on-select="true"
            :searchable="false"
            track-by="id"
            label="name"
          >
            <template #noOptions>You don't have a character yet, go make one!</template>
          </multiselect>
        </div>
      </div>
      <div>
        <opened-fan-work
          :is-dec="true"
          :fanwork="decorationfanwork"
          :page-size="pageSize"
          :total-count="totalDecCount"
          :get-current-page="currentDecPage"
          @currentPage="getDecPage"
        />
      </div>
    </div>

    <div>
      <div class="sub-title mt-4">
        Your Decoration <span v-if="totalYourDecCount"> ({{ totalYourDecCount }}) </span>
      </div>
      <div class="mt-2">Pieces of Decoration you've gifted other users.</div>

      <div class="d-flex py-3">
        <div class="mt-2">Filter by:</div>
        <div class="mx-2">
          <multiselect
            v-model="filters.decreceiver"
            class="choose-options"
            placeholder="@Username"
            :options="submittedusers"
            :multiple="false"
            :taggable="false"
            open-direction="bottom"
            :close-on-select="true"
            :searchable="false"
            track-by="id"
            label="username"
          >
            <template #noOptions>You haven't submitted any FanWork</template>
          </multiselect>
        </div>
      </div>
      <div>
        <submitted-fan-work
          :is-dec="true"
          :fanwork="subDec"
          :page-size="pageSize"
          :total-count="totalYourDecCount"
          :get-current-page="currentYourDecPage"
          @currentPage="getYourDecPage"
        />
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action, Getter } from 's-vuex-class';
// eslint-disable-next-line import/no-named-as-default
import Multiselect from 'vue-multiselect';
import { Watch } from 'vue-property-decorator';
import UnopenedFanWork from './components/UnopenedFanWork.vue';
import OpenedFanWork from './components/OpenedFanWork.vue';
import SubmittedFanWork from './components/SubmittedFanWork.vue';
import { getFanWorks, fetchSubmitter } from '@/shared/actions/fanwork';

import namespace from '@/shared/store/namespace';
import { Character } from '@/shared/types/static-types';
import { getAllStoryWorlds } from '@/shared/actions/worlds';
import { authStore } from '@/shared/pinia-store/auth';

@Options({
  name: 'FanWorkPage',
  components: { UnopenedFanWork, OpenedFanWork, Multiselect, SubmittedFanWork },
})
export default class FanWorkPage extends Vue {
  public submittedFanwork: any = [];
  public subDec: any = [];
  public unOpenedFanwork: any = [];
  public openedFanwork: any = [];
  public decorationfanwork: any = [];
  public unOpenedDec: any = [];
  public submittedusers: any = [];
  public usersChar: any = [];
  public usersOcChar: any = [];
  public userWorlds: any = [];
  public pageSize = 6;
  public totalCount = 0;
  public totalDecCount = 0;
  public totalYourDecCount = 0;
  public totalYourFanworkCount = 0;

  public totalUnOpenedDecCount = 0;
  public totalUnOpenedFanworkCount = 0;

  public currentPage = 1;
  public currentDecPage = 1;
  public currentUnOpenedDecPage = 1;
  public currentUnOpenedFanworkPage = 1;
  public currentYourDecPage = 1;
  public currentYourFanworkPage = 1;

  public countMyFanWork = '';
  public countOcFanWork = '';
  public countMyDec = '';
  public countOpenedFanWork = '';
  public placeHolder = 'Character Name';
  public options: any = ['Character', 'World'];
  get = get;

  public filters: any = {
    options: null,
    character: null,
    receiver: null,
  };

  @Action('getUnopenedFanWork', { namespace: namespace.FanworksModule })
  public getUnopenedFanWork!: any;

  @Action('getUserCharacters', { namespace: namespace.CharactersModule })
  public getUserCharacters!: any;

  @Getter('userCharacters', { namespace: namespace.CharactersModule })
  public userCharacters!: Character[];

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  @Watch('filters.character', { deep: true })
  charfilterUpdated() {
    this.currentPage = 1;
    this.fetchOpenedFanWorks();
  }

  @Watch('filters.ocDecorations', { deep: true })
  charDecorationsfilterUpdated() {
    this.currentDecPage = 1;
    this.fetchOpenedFanDecorations();
  }

  @Watch('filters.options', { deep: true })
  async fetchWorldChar(value: any) {
    this.usersChar = [];
    this.filters.character = null;
    this.currentPage = 1;
    if (value === 'Character' && this.isAuthenticated) {
      await this.getUserCharacters({ id: this.user.id });
      this.usersChar = map(this.userCharacters, (char) => ({ ...char, name: char.info.name }));
      this.fetchOpenedWorldFanWorks(value);
    } else if (value === 'World' && this.isAuthenticated) {
      this.fetchUserWorlds();
      this.usersChar = map(this.usersChar, (world) => ({ ...world, name: world.name }));
      this.fetchOpenedWorldFanWorks(value);
    } else {
      this.fetchOpenedWorldFanWorks(value);
    }

    this.placeHolderName(value);
  }

  @Watch('filters.receiver', { deep: true })
  submitterfilterUpdated() {
    this.currentYourFanworkPage = 1;
    this.fetchSubmittedFanWorks();
  }

  @Watch('filters.decreceiver', { deep: true })
  submitterOcfilterUpdated() {
    this.currentYourDecPage = 1;
    this.fetchSubmittedDec();
  }

  public getPage(page: number) {
    this.currentPage = page;
    this.fetchOpenedFanWorks();
  }

  public getDecPage(page: number) {
    this.currentDecPage = page;
    this.fetchOpenedFanDecorations();
  }

  public getUnOpenedDecPage(page: number) {
    this.currentUnOpenedDecPage = page;
    this.fetchUnopenedDec();
  }

  public getUnOpenedFanworkPage(page: number) {
    this.currentUnOpenedFanworkPage = page;
    this.fetchUnopenedFanWorks();
  }

  public getYourDecPage(page: number) {
    this.currentYourDecPage = page;
    this.fetchSubmittedDec();
  }

  public getYourFanworkPage(page: number) {
    this.currentYourFanworkPage = page;
    this.fetchSubmittedFanWorks();
  }

  public placeHolderName(value: any) {
    value === 'Character' ? (this.placeHolder = 'Character Name') : (this.placeHolder = 'World Name');
  }

  public async fetchSubmitter() {
    const resp = await fetchSubmitter();
    this.submittedusers = map(resp.results, (user) => ({ ...user, username: user.username }));
  }

  public fanwork() {
    if (!isEmpty(this.unOpenedFanwork)) {
      return this.unOpenedFanwork;
    } else if (!isEmpty(this.openedFanwork)) return this.openedFanwork;
    else return this.submittedFanwork;
  }

  public async fetchUnopenedFanWorks() {
    const resp = await this.getFanWork('receiver', {
      isOpened: false,
      type: 'art',
      page: this.currentUnOpenedFanworkPage,
      page_size: 5,
    });
    this.unOpenedFanwork = resp;
  }

  public async fetchOpenedFanWorks() {
    const resp = await this.getFanWork('receiver', { isOpened: true, type: 'art', page: this.currentPage });
    this.openedFanwork = resp;
    if (this.openedFanwork && !this.filters.character) this.countOpenedFanWork = this.openedFanwork.length;
  }

  public async fetchOpenedFanDecorations() {
    const resp = await this.getFanWork('receiver', { type: 'decoration', isOpened: true, page: this.currentDecPage });
    this.decorationfanwork = resp;
    if (this.decorationfanwork && !this.filters.ocDecorations) this.countOcFanWork = this.decorationfanwork.length;
  }

  public async fetchOpenedWorldFanWorks(type: any) {
    const resp = await this.getWorldFanWork('receiver', { isOpened: true }, type);
    this.openedFanwork = resp;
    if (this.openedFanwork && !this.filters.world) this.countOpenedFanWork = this.openedFanwork.length;
  }

  public async fetchSubmittedDec() {
    const resp = await getFanWorks('submitter', {
      type: 'decoration',
      page: this.currentYourDecPage,
      page_size: this.pageSize,
      ...(this.filters.decreceiver && { receiver: this.filters.decreceiver.id }),
    });

    this.subDec = resp.results;
    this.totalYourDecCount = resp.count;

    if (!isEmpty(this.subDec) && !this.filters.receiver) this.countMyDec = this.subDec.length;
  }

  public async fetchSubmittedFanWorks() {
    const resp = await getFanWorks('submitter', {
      fanwork_types: 'art,other',
      page: this.currentYourFanworkPage,
      page_size: this.pageSize,
      ...(this.filters.receiver && { receiver: this.filters.receiver.id }),
    });
    this.submittedFanwork = resp.results;
    this.totalYourFanworkCount = resp.count;

    if (this.submittedFanwork && !this.filters.receiver) this.countMyFanWork = this.submittedFanwork.length;
  }

  public async getFanWork(type: string, params: any = {}) {
    const resp =
      params.type === 'decoration'
        ? await getFanWorks(type, {
            type: 'decoration',
            is_opened: params.isOpened,
            ...(this.filters.ocDecorations && {
              submitted_for_type: 'character',
              submitted_for_id: this.filters.ocDecorations.id,
            }),
            page: params.page || 1,
            page_size: this.pageSize,
          })
        : await getFanWorks(type, {
            is_opened: params.isOpened,
            fanwork_types: 'art,other',
            ...(this.filters.options && {
              submitted_for_type: this.filters.options === 'Character' ? 'character' : 'world',
            }),
            ...(this.filters.character && {
              submitted_for_id: this.filters.character.id,
            }),
            page: params.page || 1,
            page_size: params.isOpened ? this.pageSize : 5,
          });

    if (params.isOpened && params.type !== 'decoration') {
      this.totalCount = resp.count;
      return resp.results;
    } else if (!params.isOpened && params.type !== 'decoration') {
      this.totalUnOpenedFanworkCount = resp.count;
      return resp.results;
    } else {
      this.totalDecCount = resp.count;
      return resp.results;
    }
  }

  public async fetchUnopenedDec() {
    const resp = await getFanWorks('receiver', {
      type: 'decoration',
      is_opened: false,
      page: this.currentUnOpenedDecPage,
      page_size: 5,
    });

    this.unOpenedDec = resp.results;
    this.totalUnOpenedDecCount = resp.count;

    if (this.currentUnOpenedDecPage === 1) {
      await this.getUserCharacters({ id: this.user.id });
      this.usersOcChar = map(this.userCharacters, (char) => ({ ...char, name: char.info.name }));
      this.countOcFanWork = this.decorationfanwork.length;
    }
  }

  public async getWorldFanWork(type: string, params: any = {}, submittedForType: any) {
    const resp = await getFanWorks(type, {
      is_opened: params.isOpened,
      fanwork_types: 'art,other',
      page: 1,
      page_size: this.pageSize,
      ...(this.filters.options && { submitted_for_type: submittedForType === 'Character' ? 'character' : 'world' }),
    });

    if (params.isOpened && params.type !== 'decoration') {
      this.totalCount = resp.count;
    }

    return resp.results;
  }

  public async fetchUserWorlds() {
    const resp = await getAllStoryWorlds('story', this.user.id, 1);
    this.usersChar = resp.results;
  }

  mounted() {
    if (this.$route.query.type === 'decoration') {
      document.getElementById('decorations')?.scrollIntoView();
    }
    this.fetchUnopenedFanWorks();
    this.fetchOpenedFanWorks();
    this.fetchSubmittedDec();
    this.fetchSubmittedFanWorks();
    this.fetchSubmitter();
    this.fetchUnopenedDec();
    this.fetchOpenedFanDecorations();
  }
}
</script>
<style lang="sass" scoped>
.title
  font-size: 30px
  font-weight: bold
.sub-title
  font-size: 20px
  font-weight: bold

.choose-options
  width: 300px
  @media(max-width: 370px)
    width: 200px
  @media(max-width: 250px)
    width: 150px
::v-deep .multiselect
  .multiselect__tags
    border-radius: 20px
    border-color: #8a8888
  .multiselect__content-wrapper
    border-radius: 15px
</style>
