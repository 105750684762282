<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <div class="inner-content text-center align-items-center px-4 py-4">
      <div>{{ isDec ? 'Feature this decoration for community' : `Add FanArt to character's Gallery?` }}</div>
      <div class="d-flex justify-content-center mt-3">
        <ion-button class="confirm-btn mx-1" @click="addedFanArt">Yes</ion-button>
        <ion-button class="confirm-btn mx-1" @click="dismissModal">No</ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import FanWorkFeedBackModal from './FanWorkFeedBackModal.vue';
import { updateFeatureFanWorkGallery } from '@/shared/actions/fanwork';


@Options({
  name: 'FanWorkGalleryConfirmationModal',
  components: { FanWorkFeedBackModal },
})
export default class FanWorkGalleryConfirmationModal extends Vue {
  @Prop({ required: false }) id!: string;
  @Prop({ required: false }) note!: string;
  @Prop({ default: () => {} }) isDec!: any;
  @Prop({ default: () => '', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;

  public get windowWidth() {
    const { width } = useWindowSize();
    return width;
  }

  public dismissModal() {
    this.$emit('dismissModal');
  }

  public async addedFanArt() {
    await updateFeatureFanWorkGallery(this.id);
    this.dismissModal();
  }

  public get isMobSmallScreen() {
    return this.windowWidth.value <= 400;
  }
}
</script>

<style lang="sass" scoped>
.confirm-btn
  width: 120px

ion-modal
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 80vh
  overflow: auto
</style>
