<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="inner-content">
      <div v-if="!isEmpty(fanwork)" class="d-flex flex-column px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div v-if="isUnopened" class="title mt-3">
            <span v-if="isDec">You got Oc Decoration!</span>
            <span v-else>{{ fanwork.image ? 'You got FanArt!' : 'You got FanWork!' }}</span>
          </div>
          <div v-else class="title">
            <span v-if="isDec">Oc Decoration</span>
            <span v-else>{{ fanwork.image ? 'FanArt' : 'FanWork' }}</span>
          </div>
          <ion-buttons>
            <ion-button class="close-button text-black" @click="dismissModal()">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </div>
        <div v-if="isUnopened" class="py-3 sub-title">
          <span v-if="isDec">Oc Decoration</span>
          <span v-else>{{ fanwork.image ? 'FanArt' : 'FanWork' }}</span> of
          <router-link
            v-if="get(fanwork, 'submitted_for.type') == 'character'"
            :to="{ name: 'character-profile-new', params: { slug: fanwork.submitted_for.slug } }"
            class="text-black"
            target="_blank"
            >{{ get(fanwork.submitted_for, 'info.name', '') }}
          </router-link>
        </div>
        <div v-else class="sub-title mt-3 mb-1">
          <router-link
            v-if="get(fanwork, 'submitted_for.type') == 'character'"
            :to="{ name: 'character-profile-new', params: { slug: fanwork.submitted_for.slug } }"
            class="text-black"
            target="_blank"
            >{{ get(fanwork.submitted_for, 'info.name', '') }}
          </router-link>
        </div>

        <div class="d-flex flex-wrap align-items-center">
          <span v-if="isUnopened" class="d-flex flex-wrap align-items-center"
            >The lovely

            <router-link
              :class="{
                'text-color-username': usernameColorSubmitter && !usernameColorSubmitter.startsWith('#'),
                'bold-username mx-1': usernameColorSubmitter,
              }"
              :style="
                !usernameColorSubmitter
                  ? ''
                  : usernameColorSubmitter.startsWith('#')
                  ? `color: ${usernameColorSubmitter} !important`
                  : `background-image: ${usernameColorSubmitter} !important`
              "
              v-if="get(fanwork, 'submitter')"
              :to="{ name: 'profile', params: { username: fanwork.submitter.username } }"
              class="text-black"
              target="_blank"
              >@{{ get(fanwork, 'submitter.username', '') }}
            </router-link>
            <ProfileBadge :badges="usernameBadgeSubmitter" :show-small-badge="true" />

            gifted you a piece of
            <span v-if="isDec" class="mx-1">Oc Decoration</span>
            <span v-else>{{ fanwork.image ? 'FanArt' : 'FanWork' }}</span> of
            <router-link
              v-if="get(fanwork, 'submitted_for.type') == 'character'"
              :to="{ name: 'character-profile-new', params: { slug: fanwork.submitted_for.slug } }"
              class="text-black mx-1"
              target="_blank"
              >{{ get(fanwork.submitted_for, 'info.name', '') }}
            </router-link></span
          >
          <span v-else-if="isMyFanWork">
            <span v-if="isDec" class="d-flex flex-wrap align-items-center">Oc decoration</span>
            <span v-else class="d-flex flex-wrap align-items-center">{{ fanwork.image ? 'FanArt' : 'FanWork' }}</span>
            sent to
            <strong
              ><router-link
                :class="{
                  'text-color-username': usernameColor && !usernameColor.startsWith('#'),
                  'bold-username': usernameColor,
                }"
                :style="
                  !usernameColor
                    ? ''
                    : usernameColor.startsWith('#')
                    ? `color: ${usernameColor} !important`
                    : `background-image: ${usernameColor} !important`
                "
                :to="{ name: 'profile', params: { username: fanwork.receiver.username } }"
                target="_blank"
                class="text-black mx-1"
                >@{{ get(fanwork, 'receiver.username') }}</router-link
              >
              <ProfileBadge
                :badges="get(fanwork?.receiver.customize_profile, 'badges') || []"
                :show-small-badge="true"
              /> </strong
          ></span>
          <span v-else class="d-flex flex-wrap align-items-center">
            {{ isDec ? 'Oc Decoration' : fanwork.image ? 'FanArt' : 'FanWork' }} by

            <router-link
              :class="{
                'text-color-username': usernameColorSubmitter && !usernameColorSubmitter.startsWith('#'),
                'bold-username': usernameColorSubmitter,
              }"
              :style="
                !usernameColorSubmitter
                  ? ''
                  : usernameColorSubmitter.startsWith('#')
                  ? `color: ${usernameColorSubmitter} !important`
                  : `background-image: ${usernameColorSubmitter} !important`
              "
              :to="{ name: 'profile', params: { username: fanwork.submitter.username } }"
              class="text-black mx-1"
              target="_blank"
            >
              @{{ get(fanwork, 'submitter.username') }}
            </router-link>
            <ProfileBadge :badges="usernameBadgeSubmitter" :show-small-badge="true" />
          </span>
        </div>

        <div v-if="fanwork.image" class="mt-3 d-flex justify-content-center">
          <div class="position-relative">
            <img
              loading="lazy"
              v-image
              :src="resizeUpload(get(fanwork, 'image.image', '/empty.png'), '800w')"
              class="fanart-img"
            />

            <ion-button
              v-if="!isMyFanWork"
              class="position-absolute one-button left"
              shape="round"
              color="dark"
              @click="dropDownToggle"
            >
              <i class="ti-more-alt" />
            </ion-button>
          </div>
        </div>

        <div class="content mb-3">
          <div v-if="!fanwork.image" class="fanwork-content px-4 py-3 mt-3">
            <div class="sub-title">{{ fanwork.other_title }}</div>
            <div v-html="sanitizeHtml(fanwork.other_content)" class="text mt-2" />
          </div>
        </div>

        <div v-if="fanwork.note" class="note-text mb-3">"{{ fanwork.note }}"</div>

        <template v-if="!isMyFanWork && fanwork.image && !isUnopened">
          <hr class="w-100" />

          <div class="text">
            <span v-if="isDec">Feature this Oc Decoration for community?</span>
            <span v-else>Add this FanArt to {{ get(fanwork.submitted_for, 'info.name', '') }}'s Gallery?</span>
          </div>
          <ion-radio-group v-model="fanwork.is_featured" class="d-flex flex-column px-2 mt-2" mode="md">
            <div class="d-flex"><ion-radio :value="true" class="mr-1" />Yes</div>
            <div class="d-flex mt-2"><ion-radio :value="false" class="mt-1 mr-1" /> <span class="mt-1">No</span></div>
          </ion-radio-group>

          <div class="d-flex justify-content-center mt-2">
            <ion-button class="yay-btn" @click="saveGallery">Save</ion-button>
          </div>
        </template>

        <div v-if="isUnopened" class="d-flex justify-content-center mt-2">
          <ion-button class="yay-btn" @click="dismissModal">Yay!</ion-button>
        </div>

        <div v-if="isMyFanWork && !isUnopened && (get(fanwork, 'feedback_reactions.length') || fanwork.feedback_note)">
          <router-link
            :class="{
              'text-color-username': usernameColor && !usernameColor.startsWith('#'),
              'bold-username': usernameColor,
            }"
            :style="
              !usernameColor
                ? ''
                : usernameColor.startsWith('#')
                ? `color: ${usernameColor} !important`
                : `background-image: ${usernameColor} !important`
            "
            v-if="get(fanwork, 'receiver')"
            :to="{ name: 'profile', params: { username: fanwork.receiver.username } }"
            class="text-black"
            target="_blank"
          >
            <strong>@{{ fanwork.receiver.username }}</strong> </router-link
          >'s response

          <div class="content mb-3">
            <div class="fanwork-content px-4 py-3 mt-3">
              <div v-if="get(fanwork, 'feedback_reactions.length')" class="text">
                {{ fanwork.feedback_reactions.join(' ') }}
              </div>
              <div v-if="fanwork.feedback_note" class="text mt-3">{{ fanwork.feedback_note }}</div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="d-flex p-5">
        <ChLoading size="sm" class="mx-auto" />
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FanworkPopover from '@/shared/pages/fan-work/popovers/FanworkPopover.vue';
import {
  getFanWorkDetails,
  updateFanWorkGallery,
  updateFeatureFanWorkGallery,
  updateFanWorkStatus,
} from '@/shared/actions/fanwork';
import { popovers, toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { sanitizeHtml } from '@/shared/utils/html';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'FanWorkModal',
  components: {
    ProfileBadge,
  },
})
export default class FanWorkModal extends Vue {
  @Prop({}) id!: string;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ default: false }) isUnopened!: boolean;
  @Prop({ default: false }) isDec!: boolean;
  resizeUpload = resizeUpload;
  sanitizeHtml = sanitizeHtml;
  get = get;
  isEmpty = isEmpty;

  public fanwork: any = {};

  public get loggedInUser() {
    const { user } = authStore();
    return user.value;
  }

  public get isMyFanWork() {
    return this.loggedInUser && get(this.fanwork, 'submitter.id') === get(this.loggedInUser, 'id');
  }

  public get usernameColor() {
    return this.fanwork?.receiver.customize_profile?.username_color || '';
  }

  public get usernameColorSubmitter() {
    return this.fanwork?.submitter.customize_profile?.username_color || '';
  }

  public get usernameBadgeSubmitter() {
    return get(this.fanwork?.submitter.customize_profile, 'badges') || [];
  }

  public dismissModal() {
    this.$emit('dismissModal', { fanWork: this.fanwork });
  }

  public dropDownToggle(ev: CustomEvent) {
    popovers.open(ev, FanworkPopover, { fanwork: this.fanwork });
  }
  public async saveGallery() {
    this.fanwork.is_featured ? await updateFeatureFanWorkGallery(this.id) : await updateFanWorkGallery(this.id);
    this.fanwork.is_featured
      ? await toast.show('Featured FanArt on gallery', 'nonative', 'success')
      : await toast.show('Unfeatured FanArt from gallery', 'nonative', 'secondary');
    this.dismissModal();
    this.$emit('updated');
  }

  @Watch('isOpen')
  async openDetails(value: boolean) {
    if (value) {
      this.fanwork = null;
      this.getFanArtDetails();
      if (this.isUnopened) {
        this.updatedFanWorkStatus();
      }
    }
  }

  public async updatedFanWorkStatus() {
    await updateFanWorkStatus(this.id);
  }

  public async getFanArtDetails() {
    const resp = await getFanWorkDetails(this.id);
    this.fanwork = resp;
  }
}
</script>

<style lang="sass" scoped>
.one-button
  width: 25px
  height: 25px
  --ion-color-dark: rgba(10, 9, 40, 0.8)
  --ion-color-contrast: #FFF !important
  --padding-start: 0
  --padding-end: 0
  font-size: 16px
  right: 10px
  top: 4px
  position: absolute
  pointer-events: all
  --color: #FFF !important
.content
  max-width: 700px
  .description
    font-size: 14px
    background: #F2F2F2
    border-radius: 7px
.dark .description
  background: #060606
.img
  max-width: 100% !important
  height: auto !important
.fanart-img
  max-width: 100% !important
  max-height: 400px
.content
  max-width: 700px
  .fanwork-content
    background: #F2F2F2
    border-radius: 7px
.dark .fanwork-content
  background: #060606
.close-button
  width: 50px
.yay-btn
  width: 120px
.note-text
  font-size: 18px
  font-style: italic
.text
  font-size: 18px
.title
  font-weight: bold
  font-size: 22px
.sub-title
  font-weight: bold
  font-size: 18px

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 98vh
  overflow: auto
</style>
