<template>
  <div>
    <div v-if="fanwork && fanwork.length" class="container pr-2">
      <div class="row" :class="currentPageFanWorks && currentPageFanWorks.length === 1 ? '' : 'no-space'">
        <template v-for="fanwork in currentPageFanWorks" :key="fanwork.id">
          <div class="col-sm-6 col-md-6">
            <ion-card
              class="fanwork-card-small clickable-item-hov mt-0 py-3 px-3 mx-0 d-flex"
              @click="openFanArt(fanwork)"
            >
              <img
                loading="lazy"
                v-image
                class="img"
                :src="resizeUpload(get(fanwork, 'image.image', '/paper.png'), '80x80')"
              />

              <div class="ml-3 flex-grow-1">
                <div class="name">
                  {{
                    get(fanwork.submitted_for, 'type') === 'world'
                      ? fanwork.submitted_for.name
                      : get(fanwork.submitted_for, 'info.name')
                  }}
                </div>

                <small class="mt-1">
                  <span v-if="isDec">Oc Decoration</span>
                  <span v-else>{{ fanwork.image ? 'FanArt' : 'FanWork' }}</span> by
                  <router-link
                    :class="{
                      'text-color-username': usernameColor(fanwork) && !usernameColor(fanwork).startsWith('#'),
                      'bold-username': usernameColor(fanwork),
                    }"
                    :style="
                      !usernameColor(fanwork)
                        ? 'color: rgb(115 115 115)'
                        : usernameColor(fanwork).startsWith('#')
                        ? `color: ${usernameColor(fanwork)} !important`
                        : `background-image: ${usernameColor(fanwork)} !important`
                    "
                    :to="{ name: 'profile', params: { username: fanwork.submitter.username } }"
                  >
                    @{{ fanwork.submitter.username }}
                  </router-link>
                </small>
                <div>
                  <ion-button v-if="fanwork.is_featured" class="open-btn"> Featured on Gallery</ion-button>
                  <ion-button v-else-if="fanwork.image && !fanwork.is_featured" class="featured-btn" color="light"
                    >Not Featured on Gallery</ion-button
                  >
                </div>

                <div class="d-flex justify-content-end">
                  <small>{{ formatTimeAgo(fanwork.created) }}</small>
                </div>
              </div>
            </ion-card>
          </div>
        </template>
      </div>
      <div v-if="fanwork && fanwork.length" class="d-flex justify-content-end py-3">
        <ion-button class="back-btn mx-2" @click="setCurrentPage(-1)">
          <span> <i class="ti-angle-left icon" /></span>
        </ion-button>
        <div class="mx-1 mt-1">
          <ion-badge class="badge paging-fanwork"
            >{{ currentPage }}
            /
            {{ Math.ceil(totalCount / pageSize) }}</ion-badge
          >
        </div>

        <ion-button class="back-btn mr-4" @click="setCurrentPage(1)">
          <span> <i class="ti-angle-right icon" /></span>
        </ion-button>
      </div>
    </div>
    <div v-else class="no-data mt-4">
      <span>You don't have any {{ isDec ? 'OC Decorations' : 'opened FanWorks' }} yet!</span>
    </div>

    <fan-work-modal
      :id="selectedFanWorkId"
      :is-open="isFanWorkOpen"
      :is-dec="isDec"
      @dismiss-modal="closeFanWorkModal"
      @updated="fetchFanwork"
    />

    <fan-work-feed-back-modal
      :id="selectedFanWorkId"
      :is-open="isFeedbackOpen"
      @dismiss-modal="closeFeedbackModal"
      @selected="selectedReactions"
    />
    <thank-you-note-to-creator
      :id="selectedFanWorkId"
      :is-open="isThanksModalOpen"
      :reactions="feedbackReactions"
      :username="get(selectedFanWork, 'submitter.username', '')"
      @dismiss-modal="closeThanksModal"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { resizeUpload } from '@/shared/utils/upload';
import FanWorkModal from '../../../modals/FanWorkModal.vue';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import FanWorkFeedBackModal from '@/shared/modals/FanWorkFeedBackModal.vue';
import ThankYouNoteToCreator from '@/shared/modals/ThankYouNoteToCreator.vue';

@Options({
  name: 'OpenedFanWork',

  components: { FanWorkModal, FanWorkFeedBackModal, ThankYouNoteToCreator },
})
export default class OpenedFanWork extends Vue {
  @Prop({ default: () => {} }) fanwork!: any;
  @Prop({ default: () => {} }) pageSize!: any;
  @Prop({ default: () => {} }) totalCount!: any;
  @Prop({ default: () => 1 }) getCurrentPage!: any;

  @Prop({ default: () => {} }) isDec!: boolean;

  resizeUpload = resizeUpload;
  public formatTimeAgo = formatTimeAgo;
  public get = get;

  public currentPage = 1;

  public selectedFanWorkId = '';
  public selectedFanWork?: any;
  public feedbackReactions = null;
  public isFanWorkOpen = false;
  public isFeedbackOpen = false;
  public isThanksModalOpen = false;

  @Watch('getCurrentPage')
  updatedPagination() {
    this.currentPage = this.getCurrentPage;
  }

  public setCurrentPage(direction: any) {
    if (direction === -1 && this.currentPage > 1) {
      this.currentPage -= 1;
    } else if (direction === 1 && this.currentPage < this.totalCount / this.pageSize) {
      this.currentPage += 1;
    }
    this.$emit('currentPage', this.currentPage);
  }

  public get currentPageFanWorks() {
    return this.fanwork;
    // .slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
  }

  public usernameColor(fanwork: any) {
    return fanwork?.submitter?.customize_profile?.username_color || '';
  }

  public openFanArt(fanwork: any) {
    const router = useRouter();
    this.selectedFanWorkId = fanwork.id;
    this.isFanWorkOpen = true;
    router.replace({ name: 'fan-work', query: { id: this.selectedFanWorkId } });
  }

  public closeFanWorkModal(data: { fanWork: any }) {
    const router = useRouter();
    this.isFanWorkOpen = false;
    if (!get(data, 'fanWork.feedback_reactions.length')) {
      this.selectedFanWork = data.fanWork;
      this.isFeedbackOpen = true;
    } else {
      router.replace({ name: 'fan-work' });
    }
  }

  public selectedReactions(feedback_reactions: any) {
    this.feedbackReactions = feedback_reactions;
  }

  public closeFeedbackModal() {
    this.isFeedbackOpen = false;
    this.isThanksModalOpen = true;
  }

  public closeThanksModal() {
    const router = useRouter();
    this.isThanksModalOpen = false;
    this.selectedFanWorkId = '';
    this.selectedFanWork = null;
    router.replace({ name: 'fan-work' });
  }

  mounted() {
    const router = useRouter();

    if (router.currentRoute.value.query.id) {
      this.selectedFanWorkId = router.currentRoute.value.query.id as string;
      if (router.currentRoute.value.query.type === 'oc_decoration' && this.isDec) this.isFanWorkOpen = true;

      router.replace({ name: 'fan-work', query: { id: this.selectedFanWorkId } });
    }
  }

  public fetchFanwork() {
    this.$emit('updated');
  }
}
</script>

<style lang="sass" scoped>
.featured-btn
  width: 180px
  height: 18px
  --border-radius: 12px
  text-transform: none !important
  margin-left: -5px
  @media(max-width: 320px)
    width: 132px
    margin-left: -7px
    font-size: 12px
  @media(max-width: 280px)
    width: 110px
    margin-left: -7px
    font-size: 10px

.back-btn
  width: 25px
  height: 25px
.badge
  background: white
  color: black
  width: 100%
  height: 26px
.open-btn
  width: 145px
  height: 18px
  --border-radius: 15px
  text-transform: none !important
  margin-left: -5px
  @media(max-width: 320px)
    width: 110px
    margin-left: -7px
    font-size: 12px
  @media(max-width: 280px)
    width: 100px
    margin-left: -7px
    font-size: 10px

.no-space
  margin-left: 0
  margin-bottom: 0

.no-space [class*='col-']:not(:first-child),
.no-space [class*='col-']:not(:last-child)
  padding-right: 7px
  padding-left: 2px

.fanwork-card-small

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
    height: 90%

  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
    height: 96%
  .img
    width: 76px
    height: 76px
    object-fit: cover
  .name
    font-size: 20px
    font-weight: bold
ion-badge
    display: flex
    align-items: center
    justify-content: center
</style>
