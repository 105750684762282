<template>
  <div class="d-flex unopened-fan-work h-100 clickable-item-hov" @click.prevent="openFanArtModal">
    <ion-card class="fanwork-card mx-0 mt-0 py-3 px-3">
      <div class="d-flex">
        <div class="d-flex justify-content-center align-items-center"><i class="ti-gift icon" /></div>

        <div class="px-2 flex-grow-1">
          <div class="title text-black">
            {{ get(fanwork.submitted_for, 'info.name', '') }}
          </div>
          <small>
            <span v-if="isDec">Oc Decoration</span>
            <span v-else>{{ fanwork.image ? 'FanArt' : 'FanWork' }}</span> by
            <router-link
              :class="{
                'text-color-username': usernameColor && !usernameColor.startsWith('#'),
                'bold-username': usernameColor,
              }"
              :style="
                !usernameColor
                  ? 'color: rgb(115 115 115)'
                  : usernameColor.startsWith('#')
                  ? `color: ${usernameColor} !important`
                  : `background-image: ${usernameColor} !important`
              "
              :to="{
                name: 'profile',
                params: { username: fanwork.submitter.username },
              }"
            >
              @{{ fanwork.submitter.username }}
            </router-link></small
          >

          <div class="d-flex justify-content-between">
            <ion-button class="open-btn clickable-item-hov" @click.prevent="openFanArtModal">Click to open!</ion-button>

            <small class="ml-2 mt-2">{{ formatTimeAgo(fanwork.created) }}</small>
          </div>
        </div>
      </div>
    </ion-card>

    <fan-work-modal
      :id="id"
      :is-dec="isDec"
      :is-open="isFanWorkOpen"
      :is-unopened="true"
      @dismiss-modal="closeFanWorkModal"
    />

    <fan-work-gallery-confirmation-modal
      :id="fanwork.id"
      :is-open="isOpeningGalleryModal"
      :is-dec="isDec"
      @dismiss-modal="closeGalleryModal"
    />
    <fan-work-feed-back-modal
      :id="id"
      :is-open="isOpeningFeedBack"
      :is-dec="isDec"
      @dismiss-modal="closeFeedBackModal"
      @selected="feedbackReactions"
    />
    <thank-you-note-to-creator
      :id="id"
      :is-open="isOpenThankyouNote"
      :reactions="reactions"
      :username="fanwork.submitter.username"
      @dismiss-modal="closeModal"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { Prop } from 'vue-property-decorator';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import FanWorkModal from '@/shared/modals/FanWorkModal.vue';
import FanWorkGalleryConfirmationModal from '@/shared/modals/FanWorkGalleryConfirmationModal.vue';
import FanWorkFeedBackModal from '@/shared/modals/FanWorkFeedBackModal.vue';
import ThankYouNoteToCreator from '@/shared/modals/ThankYouNoteToCreator.vue';

@Options({
  name: 'FanWorkCard',
  components: {
    FanWorkModal,
    FanWorkGalleryConfirmationModal,
    FanWorkFeedBackModal,
    ThankYouNoteToCreator,
  },
})
export default class FanWorkCard extends Vue {
  @Prop({ default: () => {} }) fanwork!: any;
  @Prop({ default: () => {} }) isDec!: any;
  get = get;
  public formatTimeAgo = formatTimeAgo;
  public id = '';
  public reactions: any = [];

  public isFanWorkOpen = false;
  public isOpeningGalleryModal = false;
  public isOpeningFeedBack = false;
  public isOpenThankyouNote = false;

  public get usernameColor() {
    return this.fanwork?.submitter.customize_profile?.username_color || '';
  }

  public closeFanWorkModal() {
    this.isFanWorkOpen = false;
    this.fanwork.image ? (this.isOpeningGalleryModal = true) : (this.isOpeningFeedBack = true);
  }

  public feedbackReactions(feedback_reactions: any) {
    this.reactions = feedback_reactions;
  }

  public openFanArtModal() {
    const router = useRouter();
    this.id = this.fanwork.id;
    this.isFanWorkOpen = true;
    router.replace({ name: 'fan-work', query: { id: this.id } });
  }

  public closeGalleryModal() {
    this.isOpeningGalleryModal = false;
    this.isOpeningFeedBack = true;
  }

  public closeFeedBackModal() {
    this.isOpeningFeedBack = false;
    this.isOpenThankyouNote = true;
  }

  public closeModal() {
    const router = useRouter();
    this.isOpenThankyouNote = false;
    router.replace({ name: 'fan-work' });
    this.$emit('updated');
    this.$emit('opened');
  }

  public mounted() {}
}
</script>

<style lang="sass" scoped>
.icon
  width: 45px
  font-size: 40px
  margin-top: -15px
  color: var(--ion-color-primary)

.title
  font-size: 20px
  font-weight: bold

.open-btn
  width: 103px
  height: 19px
  --border-radius: 15px
  text-transform: none !important
  margin-left: -4px

.fanwork-card
  overflow: unset !important

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important

.unopened-fan-work
  overflow-x: auto
  overflow: unset !important
.unopened-fan-work::-webkit-scrollbar
  display: none
.unopened-fan-work
  -ms-overflow-style: none
  scrollbar-width: none
</style>
